import React, { useState } from 'react';
import { graphql, Script } from 'gatsby';
import PropTypes from 'prop-types';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import Navbar from '../components/navbar';
import { Footer } from '../components/footer';
import SEO from '../SEO';
import sanityConfig from '../../config/sanity';

import '../styles/global.css';
import { pageMeta, urlFor } from '../utils/common';
import CardPengaduan from '../components/cardPengaduan';
import useSmartScript from '../hooks/useSmartScript';
import { ONELINK_SCRIPT_URL } from '../configs/appsflyer';
import Wrapper from '../components/Wrapper';
import { formatDate } from '../utils/datetime';

export const query = graphql`query blogPost($datePublished: Date) {
    allSanityBlog(
      filter: {subCategories: {elemMatch: {title: {in: "BPR"}}}, publishedAt: {lte: $datePublished}}
      sort: {fields: publishedAt, order: DESC}
    ) {
      nodes {
        id
        lang
        category
        mainImageURL
        subCategories {
          title
          id
          slug {
            current
          }
        }
        slug {
          current
        }
        title
        mainImage {
          asset {
            _id
          }
        }
        publishedAt
        _rawBody
        author {
          id
          name
        }
        buttonCtas {
          url
          text
          scheme
          _type
          _rawCtaImage
          _key
          ctaImage {
            _key
            _type
            asset {
              _id
              id
            }
          }
        }
      }
    }
  }`;

const BlogCard = ({ blog }) => {
  const cardImage = getGatsbyImageData(blog.mainImage, {
    placeholder: 'blurred', layout: 'constrained', width: 400, height: 250,
  }, sanityConfig);
  return (
    <div
      className="flex flex-col w-full sm:w-1/2 xl:w-1/2 my-3 p-2"
      key={blog.id}
    >
      <SEO title="Semua Artikel" metaDescription={pageMeta.mainDesc} />
      <div className="flex flex-row">
        <a href={`/blog/${blog.slug.current}`} className="my-5 block min-h-fit">
          <GatsbyImage
            image={cardImage}
            className="rounded-xl cursor-pointer object-cover image w-44 lg:w-64"
            loading="lazy"
            title={blog.title}
          />
        </a>
        <div className="flex flex-col self-center ml-4 lg:ml-5">
          <div className="text-xs lg:text-sm mb-1 lg:mb-2 text-blue-light40">
            {blog.subCategories.map((category) => (
              <span>{category.title}/</span>
            ))}
          </div>
          <p className="article-title">
            <a
              className="hover:no-underline cursor-pointer font-bold"
              href={`/blog/${blog.slug.current}`}
            >
              {blog.title}
            </a>
            <p className="text-xs font-normal text-primary cursor-pointer mb-1 mt-3">
              {blog.author ? blog.author.name : 'Komunal Indonesia'}
            </p>
            <p className="text-xs font-normal text-primary">{formatDate({ date: blog.publishedAt })}</p>
          </p>
        </div>
      </div>
    </div>
  );
};

BlogCard.propTypes = {
  blog: PropTypes.object.isRequired,
};

export default function BlogPage({ data }) {
  const { allSanityBlog } = data;
  const blogsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = allSanityBlog.nodes.slice(indexOfFirstBlog, indexOfLastBlog);

  const cardImage = getGatsbyImageData(data.allSanityBlog.nodes[0].mainImage, {
    placeholder: 'blurred', layout: 'constrained', width: 800,
  }, sanityConfig);
  const cardImage1 = getGatsbyImageData(data.allSanityBlog.nodes[1].mainImage, {
    placeholder: 'blurred', layout: 'constrained', width: 256, height: 160,
  }, sanityConfig);
  const cardImage2 = getGatsbyImageData(data.allSanityBlog.nodes[2].mainImage, {
    placeholder: 'blurred', layout: 'constrained', width: 256, height: 160,
  }, sanityConfig);
  const cardImage3 = getGatsbyImageData(data.allSanityBlog.nodes[3].mainImage, {
    placeholder: 'blurred', layout: 'constrained', width: 256, height: 160,
  }, sanityConfig);

  const { smartScriptUrl, setIsSmartScriptLoaded } = useSmartScript();

  return (
    <Wrapper>
      <SEO
        title="Blog dan Artikel"
        metaDescription={pageMeta.mainDesc}
        metaImage={urlFor(allSanityBlog.nodes[0].mainImage).url()}
        type="website"
      />
      <Script src={ONELINK_SCRIPT_URL} onLoad={() => { setIsSmartScriptLoaded(true); }} />
      <Navbar />
      <div className="relative">
        <div className="px-8 py-4 lg:px-0 lg:py-0 lg:mb-10">
          <div className="container mx-auto lg:py-16 lg:grid lg:grid-cols-8">
            <div className="col-span-5 lg:mt-0">
              <GatsbyImage
                image={cardImage}
                className="rounded-2xl mb-7"
                loading="lazy"
              />
              <div className="col-span-3 lg:self-center">
                <div className="text-xl lg:text-sm mb-1 lg:mb-3 text-blue-light40">
                  <p>
                    {allSanityBlog.nodes[0].subCategories.map((category) => (
                      <span>{category.title}/</span>
                    ))}
                  </p>
                </div>
                <a href={`/blog/${allSanityBlog.nodes[0].slug.current}`} className="lg:text-3xl lg:py-4 font-bold">
                  {allSanityBlog.nodes[0].title}
                </a>
                <p className="py-4">
                  {allSanityBlog.nodes[0]._rawBody[0].children[0].text}
                </p>
                {allSanityBlog.nodes[0].author === null ? (
                  <p className="lg:mt-5">
                    DepositoBPR by Komunal
                    <br />
                    {formatDate({ date: allSanityBlog.nodes[0].publishedAt })}
                  </p>
                )
                  : (
                    <p className="lg:mt-5">
                      {allSanityBlog.nodes[0].author.name}
                      <br />
                      {formatDate({ date: allSanityBlog.nodes[0].publishedAt })}
                    </p>
                  )}
              </div>
            </div>
            <div className="col-span-3 lg:ml-12 mt-5 lg:mt-0">
              <a className="lg:text-2xl lg:py-4 font-bold text-xl">
                Recent Articles
              </a>
              <div className="grid grid-cols-10 lg:mt-6 mb-10 mt-5">
                <div className="lg:col-span-4 col-span-5 flex items-center justify-center">
                  <GatsbyImage
                    image={cardImage1}
                    className="rounded-xl cursor-pointer object-cover overflow-hidden"
                    loading="lazy"
                  />
                </div>
                <div className="col-span-5 lg:col-span-6 flex flex-col self-center ml-4 lg:ml-5">
                  <div className="text-xs lg:text-sm mb-1 lg:mb-2 text-blue-light40">
                    {allSanityBlog.nodes[1].subCategories.map((category) => (
                      <span>{category.title}/</span>
                    ))}
                  </div>
                  <p className="article-title">
                    <a
                      className="hover:no-underline cursor-pointer font-bold"
                      href={`/blog/${allSanityBlog.nodes[1].slug.current}`}
                    >
                      {allSanityBlog.nodes[1].title}
                    </a>
                    <p className="text-xs font-normal text-primary cursor-pointer mb-1 mt-3">
                      {allSanityBlog.nodes[1].author ? allSanityBlog.nodes[1].author.name : 'Komunal Indonesia'}
                    </p>
                    <p className="text-xs font-normal text-primary">{formatDate({ date: allSanityBlog.nodes[1].publishedAt })}</p>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-10 lg:mt-8 mb-10">
                <div className="lg:col-span-4 col-span-5 flex items-center justify-center">
                  <GatsbyImage
                    image={cardImage2}
                    className="rounded-xl cursor-pointer object-cover overflow-hidden"
                    loading="lazy"
                  />
                </div>
                <div className="col-span-5 lg:col-span-6 flex flex-col self-center ml-4 lg:ml-5">
                  <div className="text-xs lg:text-sm mb-1 lg:mb-2 text-blue-light40">
                    {allSanityBlog.nodes[2].subCategories.map((category) => (
                      <span>{category.title}/</span>
                    ))}
                  </div>
                  <p className="article-title">
                    <a
                      className="hover:no-underline cursor-pointer font-bold"
                      href={`/blog/${allSanityBlog.nodes[2].slug.current}`}
                    >
                      {allSanityBlog.nodes[2].title}
                    </a>
                    <p className="text-xs font-normal text-primary cursor-pointer mb-1 mt-3">
                      {allSanityBlog.nodes[2].author ? allSanityBlog.nodes[2].author.name : 'Komunal Indonesia'}
                    </p>
                    <p className="text-xs font-normal text-primary">{formatDate({ date: allSanityBlog.nodes[1].publishedAt })}</p>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-10 lg:mt-8 mb-10">
                <div className="lg:col-span-4 col-span-5 flex items-center justify-center">
                  <GatsbyImage
                    image={cardImage3}
                    className="rounded-xl cursor-pointer object-cover overflow-hidden"
                    loading="lazy"
                  />
                </div>
                <div className="col-span-5 lg:col-span-6 flex flex-col self-center ml-4 lg:ml-5">
                  <div className="text-xs lg:text-sm mb-1 lg:mb-2 text-blue-light40">
                    {allSanityBlog.nodes[3].subCategories.map((category) => (
                      <span>{category.title}/</span>
                    ))}
                  </div>
                  <p className="article-title">
                    <a
                      className="hover:no-underline cursor-pointer font-bold"
                      href={`/blog/${allSanityBlog.nodes[3].slug.current}`}
                    >
                      {allSanityBlog.nodes[3].title}
                    </a>
                    <p className="text-xs font-normal text-primary cursor-pointer mb-1 mt-3">
                      {allSanityBlog.nodes[3].author ? allSanityBlog.nodes[3].author.name : 'Komunal Indonesia'}
                    </p>
                    <p className="text-xs font-normal text-primary">{formatDate({ date: allSanityBlog.nodes[1].publishedAt })}</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-blue-light40 px-8 lg:px-0 py-0">
          <StaticImage
            src="../images/vector-calc2.png"
            alt="vector-img"
            objectFit="cover"
            className="blog-vector lg:w-1/4 w-1/2 lg:block w-800"
          />
          <div className="container mx-auto lg:py-10 lg:grid lg:grid-cols-6">
            <div className="text-white lg:self-center lg:col-span-2 pt-5 lg:pt-0">
              <StaticImage
                src="../images/logo.png"
                alt="vector-img"
                objectFit="cover"
                className="w-32 mt-5 mb-5 lg:mt-0 lg:w-40"
              />
              <br />
              <a className="text-xl lg:text-3xl lg:py-4 font-bold">
                Coba Sekarang Juga Produk Simpanan Rasa Insvestasi!
              </a>
              <br />
              <a href={smartScriptUrl} target="_blank" rel="noreferrer">
                <StaticImage
                  src="../images/Google.png"
                  alt="Google-logo"
                  objectFit="contain"
                  className="lg:w-32 mr-3 mt-5 w-24"
                  placeholder="none"
                />
              </a>
              <a href={smartScriptUrl} target="_blank" rel="noreferrer">
                <StaticImage
                  src="../images/Apple.png"
                  alt="apple-logo"
                  objectFit="contain"
                  className="lg:w-32 mt-5 w-24"
                  placeholder="none"
                />
              </a>
            </div>
            <div className="lg:col-span-1" />
            <div className="mt-3 lg:mt-0 lg:col-span-3 lg:mb-0 mb-8 pb-10 lg:pb-0">
              <StaticImage
                src="../images/character.png"
                alt="investation app"
                objectFit="contain"
                className="rounded-2xl"
              />
            </div>
          </div>
        </div>
        <div className="container mx-auto flex flex-wrap w-full mt-16">
          {currentBlogs.map((blog) => (
            <BlogCard blog={blog} key={blog.id} />
          ))}
        </div>
        <div className="flex justify-center mt-4 mb-16">
          <button
            type="button"
            className={`mx-1 px-3 py-1 text-${currentPage === 1 ? 'gray' : 'blue-dark300'} font-medium text-xl`}
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt;
          </button>

          {Array.from(
            { length: Math.ceil(allSanityBlog.nodes.length / blogsPerPage) },
            (_, index) => {
              const isFirstPage = index === 0;
              const isLastPage = index === Math.ceil(allSanityBlog.nodes.length / blogsPerPage) - 1;
              const isCurrentPage = index + 1 === currentPage;

              // Number of pages to show before and after the current page
              const pagesToShowBeforeAndAfter = 2;

              // Determine whether to show the page number
              const shouldShowPageNumber = isFirstPage || isLastPage || isCurrentPage || Math.abs(index + 1 - currentPage) <= pagesToShowBeforeAndAfter;

              // Determine whether to show the ellipsis before and after the current page
              const shouldShowEllipsisBefore = !isFirstPage && !shouldShowPageNumber && Math.abs(index - currentPage) === pagesToShowBeforeAndAfter;
              const shouldShowEllipsisAfter = !isLastPage && !shouldShowPageNumber && Math.abs(index + 2 - currentPage) === pagesToShowBeforeAndAfter;

              return (
                <React.Fragment key={index}>
                  {shouldShowEllipsisBefore && (
                    <span className="mx-1 text-blue-dark300 text-xl font-medium">...</span>
                  )}
                  {shouldShowPageNumber && ( // Only render if shouldShowPageNumber is true
                    <button
                      type="button"
                      className={`mx-1 px-3 py-1 rounded-lg font-medium ${
                        isCurrentPage
                          ? 'border-2 border-blue-light40 bg-blue-500 text-blue-light40'
                          : 'text-blue-dark300'
                      }`}
                      onClick={() => setCurrentPage(index + 1)}
                    >
                      {index + 1}
                    </button>
                  )}
                  {shouldShowEllipsisAfter && (
                    <span className="mx-1 text-blue-dark300 text-xl font-medium">...</span>
                  )}
                </React.Fragment>
              );
            },
          )}

          <button
            type="button"
            className={`mx-1 px-3 py-1 text-${
              currentPage >= Math.ceil(allSanityBlog.nodes.length / blogsPerPage) ? 'gray' : 'blue-dark300'
            } font-medium text-xl`}
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage >= Math.ceil(allSanityBlog.nodes.length / blogsPerPage)}
          >
            &gt;
          </button>
        </div>
      </div>
      <CardPengaduan />
      <Footer />
    </Wrapper>
  );
}

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
};
